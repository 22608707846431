
// Color system

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #373a3c !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #2780E3 !default;
$indigo:  #6610f2 !default;
$purple:  #613d7c !default;
$pink:    #e83e8c !default;
$red:     #e43 !default;
$orange:  #f0ad4e !default;
$yellow: #c79a08 !default;
$green:   #5c4 !default;
$teal:    #20c997 !default;
$cyan:    #9954BB !default;

$primary:       #007375 !default;
$secondary:     #333333 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

// Options

$enable-rounded: false !default;
$box-shadow: 0 0 2rem 0 rgba(136,152,170,0.25);

// Body

$body-color: $gray-900 !default;
$body-bg: #e9f0f2;

// Fonts

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base: 0.9375rem !default;
$headings-font-weight: 600 !default;

// Forms

$input-focus-border-color: #8bc7bf;

// Navbar

$navbar-dark-hover-color:           rgba($white,1) !default;
$navbar-light-hover-color:          rgba($black,.9) !default;

// Alerts

$alert-border-width: 0 !default;
$btn-border-width: 0 !default;

// Card

$card-border-width: 0;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
