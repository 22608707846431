@import './variables';

body {
    display: flex;
    flex-direction: column;
    height: 100vh;

    > div.container { flex: 1; }
}

#sln {
    height: 36px;
    background: #2f2f2f;
}

.sln-connect:before {
    content: 'Login with Symfony';
    float: left;
}

.box-shadow {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.25);
}

.flash-messages {
    text-align: center;

    .alert {
        margin: 0;
    }
}

.header {
    background: #fbfeff;
    border-bottom: 1px solid #d9e0e2;
    padding: 20px 0;

    &-logo {
        width: 100%;
        max-width: 220px;
    }
}

.footer {
    background: #333;
    color: #aaa;
    margin-top: 100px;

    &-logo {
        height: 30px;
    }

    a {
        display: inline-block;
        margin: 5px 10px;
        font-size: 13px;
        color: #fff;
    }
}

.home {
    &-intro {
        background: $primary;
        color: #fff;
        position: relative;

        .header {
            background: transparent;
            border: 0;
        }

        .nav-link {
            color: #fff;
            text-decoration: none;

            &:hover, &:active, &:visited {
                color: #fff;
                text-decoration: underline;
            }
        }

        &-message {
            &-main {
                font-family: Oswald,Helvetica,sans-serif;
                font-size: 60px;
                text-transform: uppercase;
                letter-spacing: -1px;
                font-weight: 600;
                line-height: 58px;
            }

            &-sub {
                display: inline-block;
                max-width: 600px;
                font-size: 26px;
                font-weight: 300;
                line-height: 38px;
                color: rgba(255, 255, 255, 0.65);
            }

            &-caption {
                font-size: 26px;
                font-weight: 300;
                line-height: 38px;
                color: rgba(255, 255, 255, 0.65);
            }

            &-typed {
                display: inline-block;
                width: 263px;
                text-align: left;
                color: #fff;
                font-weight: 500;
            }
        }

        &-button {
            background: lighten($primary, 4%);
            font-size: 1.4rem;
        }

        &-animation {
            padding-top: 40px;
        }

        @keyframes jacket-float {
            0% { bottom: -15px; }
            50% { bottom: 15px; }
            100% { bottom: -15px; }
        }

        &-jacket {
            position: absolute;
            height: 160px;
            bottom: 0;
            left: 100px;
            animation-name: jacket-float;
            animation-duration: 2.5s;
            animation-iteration-count: infinite;
        }

        @keyframes wave-move {
            0% { left: 0; }
            100% { left: -53px; }
        }

        &-wave {
            position: absolute;
            height: 40px;
            width: 100%;
            bottom: -15px;
            left: 0;
            overflow: hidden;

            &-image {
                background: url('/res/home/wave.png') repeat-x;
                background-size: contain;
                position: absolute;
                width: 120%;
                height: 40px;
                animation-name: wave-move;
                animation-duration: 1.5s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }
    }

    &-testimonials .lead {
        font-size: 1.3125rem
    }

    &-features {
        &-item {
            font-size: 1.04rem;
        }

        i {
            font-size: 1.4rem;
        }

        &-title {
            font-weight: 400;
            font-size: 1.12rem;
        }

        &-screenshot {
            width: 130%;
        }
    }

    &-customers {
        background: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-height: 40px;
            max-width: 200px;
            margin: 15px 25px;
        }
    }

    &-focused {
        font-size: 22px;
        font-weight: 300;
    }
}

.features {
    &-block {
        font-size: $font-size-lg;

        i {
            font-size: 32px;
            color: $primary;
        }
    }
}

.docs {
    &-chapter {
        &-icon {
            font-size: 40px;
            color: #555;
        }

        &-title {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: normal;
            color: #4fb70b;
        }

        &-sections, &-sections li {
            font-size: 14px;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        &-read-more a {
            display: inline-flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 12px;
            margin-top: 10px;
            color: #999;
        }
    }

    &-page {
        & > ul > li {
            font-weight: bold;
        }

        &-menu {
            padding: 40px 20px;

            &-home {
                display: block;
                padding: 10px 20px;
                text-decoration: none;
                font-size: 18px;
                font-weight: bold;

                i {
                    margin-right: 10px;
                }

                span {
                    border-bottom: 1px solid transparent;
                }

                &:hover, &:active, &:focus {
                    color: #333;
                    text-decoration: none;

                    span {
                        border-bottom: 1px solid #333;
                    }
                }
            }

            ul, li {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            .toctree-l1 {
                &.inactive > ul {
                    display: none;
                }

                &.active > ul {
                    display: block;
                }

                & p {
                    margin-bottom: 0;
                }

                & p > a {
                    display: block;
                    margin-top: 20px;
                    padding: 5px 10px;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: normal;
                    color: #000;
                    text-decoration: none;

                    &:hover, &:active, &:focus {
                        text-decoration: underline;
                        color: #000;
                    }

                    i {
                        margin-right: 4px;
                    }
                }
            }

            .toctree-l2 {
                & > a {
                    display: block;
                    font-size: 14px;
                    padding: 5px 0 5px 10px;
                    text-decoration: none;
                    color: #666;

                    &:hover {
                        text-decoration: none;
                        color: #000;
                    }
                }

                &.active > a {
                    color: $primary;
                    font-weight: bold;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        &-content {
            color: #444;
            background: #fff;
            box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .25);

            p, ul li, ol li, blockquote {
                font-size: 1.1em;
            }

            blockquote {
                font-style: italic;
                margin: 1.5em 0;
            }

            li ul, li ul {
                margin-top: 1em;
            }

            li li, li p {
                font-size: 1em;
            }

            p + div,
            p + p,
            img + p,
            img + ul, img + ol {
                margin-top: 1.5em;
            }
            li p + p {
                margin-top: 1em;
            }

            div.step > p {
                display: inline-block;
            }
            div.step > p {
                background: #92DB33;
                color: #fff;
                height: 36px;
                width: 36px;
                border-radius: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                float: left;
                font-size: 21px;
                font-weight: bold;
                margin: 0 .5em .5em 0;
            }

            ol.steps {
                counter-reset: step;

                > li {
                    list-style: none;
                    margin-bottom: 1.5rem;
                    position: relative;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    li {
                        margin-bottom: 1rem;
                    }
                }

                > li::marker {
                    // markers don't allow to set a background color, borders, etc. so we
                    // use a ::before pseudo-element instead of the marker
                    display: none;
                }

                > li::before {
                    counter-increment: step;
                    content: counter(step);
                    background: #007375;
                    color: #fff;
                    height: 28px;
                    width: 28px;
                    border-radius: 18px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                    position: absolute;
                    left: -42px;
                    top: 3px;
                }
            }

            code {
                border: none;
                color: #008000;
                font-size: 14px;
                width: 100%;
                overflow-wrap: anywhere;
            }

            img {
                border: 1px solid #EFEFEF;
                box-shadow: 1px 1px 5px #999999;
                display: block;
                margin: 20px auto;
                max-width: 650px;
                width: 100%;
                padding: 5px;
            }

            table {
                margin: 2em 0;
            }
            table td {
                padding: .5em;
            }

            h1, h1 > a {
                font-size: 28px;
                margin-bottom: 20px;
                color: #000;
            }

            h2, h2 > a {
                font-size: 22px;
                font-weight: bold;
                color: #000;
            }

            h3, h3 > a {
                font-size: 18px;
                font-weight: bold;
                font-family: "courier new", courier, typewriter, monospace;
                color: #000;
            }

            .section .section {
                clear: both;
                margin-top: 3em;
            }

            .figure {
                display: block;
                margin: 40px 0;
            }
        }
    }

    &-card {
        min-height: 280px;
    }
}

.what-we-cover {
    &-risks {
        width: 160px;

        &-fixed {
            position: fixed;
            top: 50px;
        }

        a:hover {
            text-decoration: none;

            i, h5 {
                color: $primary;
            }
        }
    }

    &-severity {
        float: left;
        display: inline-block;
        background: #ffe900;
        color: #333;
        width: 75px;
        padding: 1px 3px;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 19px;
        text-align: center;
        margin-right: 10px;

        &-critical {
            background: $danger;
            color: #fff;
        }

        &-major {
            background: $warning;
            color: #fff;
        }
    }
}

.dashboard {
    &-grade {
        max-height: 45px;

        @media (min-width: 768px) {
            max-height: 50px;
        }
    }

    &-title {
        font-size: $font-size-sm;

        h4 {
            font-size: 1.05rem;
        }
    }

    &-status {
        font-size: 12px;

        &-legend {
            font-size: 12px;
            text-transform: uppercase;
        }
    }

    &-analysis {
        text-align: center;
        font-size: 16px;
        transition: all .2s;

        &:hover {
            text-decoration: none;

            h5 {
                text-decoration: underline;
            }
        }

        &-message {
            font-size: 10px;

            i {
                font-size: 20px;
                margin-top: 4px;
            }

            h5 {
                font-size: 10px;
                margin: 0;
                text-transform: uppercase;
            }
        }

        &-success, &-success:hover {
            color: #46b835;
            background: #e6f7e3;
        }

        &-warning, &-warning:hover {
            color: $yellow;
            background: #fff0de;
        }

        &-error, &-error:hover {
            color: #e43;
            background: #ffeae8;
        }
    }
}

.project {
    &-start {
        &-link {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 180px;
            padding: 20px;
            background: #f5f5f5;
            text-decoration: none;
            text-align: center;
            color: #444;

            &:hover {
                color: #444;
                text-decoration: none;

                h4 {
                    text-decoration: underline;
                }
            }

            i {
                font-size: 50px;
            }

            h4 {
                margin: 15px 0 0 0;
                padding: 0;
                font-size: 18px;
                font-weight: bold;
            }

            &-loader {
                height: 50px;
                box-sizing: border-box;
                padding-top: 5px;
                font-style: italic;
                display: none;
            }
        }

        &-more {
            text-align: center;

            &, a {
                color: #999;
                font-size: 12px;
            }
        }
    }
}
